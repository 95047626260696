import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '@tidsbanken/components/buttons/Button';
import Input from '@tidsbanken/components/Input';
import Notification, { Kind } from '@tidsbanken/components/Notification';
import { AxiosError } from 'axios';
import meApi from '~src/Helpers/apiHelpers';
import { TBLocation, User } from '../../Helpers/Types';
import logoLink from '../../images/Tidsbanken.png';
import Loading from '../Loading/Loading';
import './Login.less';

type Props = {
    locationCode: string | undefined;
    tblocation: TBLocation | undefined;
    setRegisterMode: (registerMode: boolean) => void; //https://github.com/parcel-bundler/parcel/issues/7726
    setLoggedInUser: (user: User | undefined) => void;
    resetLocation: () => void;
    loggedInUser: User | undefined;
};

const Login = ({ locationCode, setLoggedInUser, setRegisterMode, tblocation, resetLocation, loggedInUser }: Props) => {
    // card inputs
    const [cardNumber, setCardNumber] = useState<string>('');
    const [cardData, setCardData] = useState<string>('');

    // notifications
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [notificationKind, setNotificationKind] = useState<Kind>();
    const [actionLabel, setActionLabel] = useState<string>('');

    // loadings
    const [isScanningCard, setIsScanningCard] = useState<boolean>(false);
    const [canShowRemoveCard, setCanShowRemoveCard] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // timeouts
    const [timeoutShowNotification, setTimeoutShowNotification] = useState<NodeJS.Timeout>();

    // hooks end

    const cardReaderRecognitionKey = '163825092';
    const successfulScan = '1001;';
    const unsuccessfulScan = '1002;';

    const formHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.persist();
        e.preventDefault();

        cardData.includes(cardReaderRecognitionKey) &&
            console.error('Posted card data with card reader recognition key included');

        setIsLoading(true);
        stampingCard();
    };

    async function stampingCard() {
        await meApi
            .post<User>('/stemplingfromreg2build', {
                LocationCodeId: locationCode,
                KortKode: cardNumber?.includes(cardReaderRecognitionKey) ? '' : cardNumber,
                KortleserData: cardData.includes(cardReaderRecognitionKey) ? cardData : '',
            })
            .then((response) => {
                setNotificationKind(response.data.Kind);
                setNotificationMessage(response.data.Message);

                if (response.status === 200) {
                    setLoggedInUser(response.data);
                    // card inputs
                    setCardData('');
                    setCardNumber('');

                    // notifications
                    setActionLabel('');
                    setIsNotificationVisible(true);

                    // scanning card
                    setIsScanningCard(false);
                    setCanShowRemoveCard(false);

                    setIsLoading(false);
                } else if (response.status >= 500) {
                    // should something happen right after successful stempling
                    clearTimeout(timeoutShowNotification as NodeJS.Timeout);

                    // card inputs
                    setCardData('');
                    setCardNumber('');

                    // notifications
                    setIsNotificationVisible(true);
                    setActionLabel('');
                    setNotificationMessage('Something went wrong, contact support.');
                    setNotificationKind('error');

                    // loadings
                    setIsScanningCard(false);
                    setCanShowRemoveCard(false);
                    setIsLoading(false);
                } else {
                    // should something happen right after successful stempling
                    clearTimeout(timeoutShowNotification as NodeJS.Timeout);

                    // card inputs
                    setCardNumber('');
                    setCardData('');

                    // notifications
                    setIsNotificationVisible(true);
                    if (response.data.Message.includes('try again or')) {
                        setActionLabel('register new card.');
                    } else {
                        setActionLabel('');
                    }

                    // loadings
                    setIsScanningCard(false);
                    setIsLoading(false);

                    console.error(response.data.Description);
                }
            })
            // catching other unexpected things like no network etc
            .catch((err: AxiosError) => {
                console.error(err.message);
                // should something happen right after successful stempling
                clearTimeout(timeoutShowNotification as NodeJS.Timeout);

                // card inputs
                setCardData('');

                // notifications
                setIsNotificationVisible(true);
                setNotificationMessage('Something went wrong, contact support.');
                setNotificationKind('error');

                // loadings
                setCanShowRemoveCard(false);
                setIsLoading(false);
            });
    }

    const cardNumberChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setCardNumber(e.target.value);
    };

    function registerCardData(e: KeyboardEvent) {
        const keyInput = e.key !== 'Enter' ? e.key : ';';

        setCardData((prevState: string) => {
            const newCardData = prevState + keyInput;
            return newCardData.replace(/.*(?=163825092)/, '');
        });
    }

    useEffect(() => {
        const regex = new RegExp(`(${cardReaderRecognitionKey}.*${successfulScan})`, 'g');
        const matchCardData = cardData.match(regex);

        if (cardData.includes(cardReaderRecognitionKey)) {
            setIsLoading(true);
            setIsScanningCard(true);
            setIsNotificationVisible(false);

            // We set and unset scanning card specific states
            if (cardData.endsWith(successfulScan) && matchCardData && matchCardData[0].length > 0) {
                // card inputs
                setCardData(matchCardData[0]);
                setCardNumber('');

                stampingCard();

                // loadings
                setIsScanningCard(false);
                setCanShowRemoveCard(true);
            }

            if (cardData.endsWith(unsuccessfulScan)) {
                clearTimeout(timeoutShowNotification as NodeJS.Timeout);

                // card inputs
                setCardData('');
                setCardNumber('');

                // notifications
                setIsNotificationVisible(true);
                setNotificationMessage('Card not read, try again but hold card still');
                setNotificationKind('error');

                // loadings
                setIsScanningCard(false);
            }
        }
    }, [cardData]);

    useEffect(() => {
        window.addEventListener('keyup', registerCardData);
        return () => {
            window.removeEventListener('keyup', registerCardData);
        };
    }, []);

    useEffect(() => {
        const user = loggedInUser;

        if (user) {
            // should new user stamp right after we clear timer
            clearTimeout(timeoutShowNotification as NodeJS.Timeout);

            // remove notifications after 5 seconds
            setTimeoutShowNotification(setTimeout(() => setIsNotificationVisible(false), 5000));
        }
    }, [loggedInUser]);

    return (
        <div className="login">
            <div className="image-container">
                <img src={logoLink} alt="logo" />
            </div>
            <h2 className="header">Scan or register HMS card</h2>

            {isNotificationVisible && (
                <Notification
                    kind={notificationKind}
                    label={notificationMessage}
                    size="small"
                    className="notification-component"
                    actionLabel={actionLabel}
                    onActionClick={() => {
                        actionLabel.length > 0 ? setRegisterMode(true) : undefined;
                    }}
                />
            )}

            {
                // WARNING: We can not use "{!isScanningCard && !isLoading &&  ...}" here because key input causes batching of state updates and we will not always get the correct state
                // this causes the form to be trigger when the enter key is pressed by the card reader. ( the card reader acts like a keyboard )
            }
            {!cardData.includes(cardReaderRecognitionKey) && (
                <form className="form-container" onSubmit={formHandler}>
                    <Input
                        autoFocus
                        type="number"
                        onChange={cardNumberChangeHandler}
                        placeholder="HMS Card Number"
                        value={cardNumber}
                    />

                    <Button label="Enter" type="submit" kind="success" />
                </form>
            )}

            {isScanningCard && <h1>PLEASE HOLD CARD STILL</h1>}
            {canShowRemoveCard && !isScanningCard && <h1>PLEASE REMOVE CARD</h1>}

            <Loading visible={isLoading} />
            <div className="footer">
                <div>
                    You are at <strong>{tblocation?.Navn}</strong>
                </div>

                <div onClick={resetLocation} className="redirect">
                    <strong>Click here to change location</strong>
                </div>
            </div>
        </div>
    );
};

export default Login;
