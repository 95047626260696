import React from "react";
import "./style.less";

// remove error private prop error
export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    /** A label for the input field */
    label?: string;
    /** A custom class to be used in styling the component */
    className?: string;
    /** Force onChange to be used */
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /** Determine if inline style or not */
    inline?: boolean;
    /**Desides type of input field:
     *
     *  text, radio, checkbox, range, password, email, number, date, time, osv...*/
    type?: string;
}

/**
 * Input.
 * @param props.className DOM class name.
 * @param props.label Text to add to label, needs id attribute
 * @param props.onChange An onChange function handler.
 * @param props.inline Determine if inline style or not.
 * @param props.type Desides type of input field.
 */

// take out what we don't want to include in attributes like label since it don't exist in HTMLInputElement
// className and type is already in use as attributes
const Input: React.FunctionComponent<Props> = ({
    label,
    className,
    type = "text",
    inline,
    ...attributes
}: Props) => {
    const id = attributes.id;
    const isLabelShowing = label && id;
    const isInputRightSide = isLabelShowing && (type == "radio" || type == "checkbox");

    return (
        <>
            {!isInputRightSide && <label className="Label left-label" htmlFor={id}>{label}</label>}
            <input
                {...attributes}
                className={`${inline &&
                    type != "color" &&
                    type != "radio" &&
                    type != "checkbox" &&
                    type != "range"
                    ? "InputInline"
                    : type == "radio" || type == "checkbox" || type == "range"
                        ? "RadioButton"
                        : type == "color"
                            ? "ColorButton"
                            : "Input"} ${className ?? ""}`}
                type={type}
            />
            {isInputRightSide && (
                <label className="Label right-label" htmlFor={id}>{label}</label>
            )}
        </>
    );
};

export default Input;
