import { useEffect, useState } from 'react';
import type { TBLocation, User } from '../Helpers/Types';
import LandingPage from './LandingPage/LandingPage';
import Login from './Login/Login';
import Print from './Print/Print';
import Register from './Register/Register';
import './App.less';

const App = (): JSX.Element => {
    const [locationCode, setLocationCode] = useState<string | undefined>();
    const [location, setLocation] = useState<TBLocation | undefined>();
    const [registerMode, setRegisterMode] = useState<boolean>(false);
    const [loggedInUser, setLoggedInUser] = useState<User | undefined>();

    const handleSetLocationCode = (locCode: string) => {
        setLocationCode(locCode);
    };

    const handleSetLocation = (location: TBLocation) => {
        setLocation(location);
    };

    const [kundeId, setKundeId] = useState<string>();

    const handleSetKundeId = (kundeId: string) => {
        setKundeId(kundeId);
    };

    const handleSetRegisterMode = (registerMode: boolean) => {
        setRegisterMode(registerMode);
    };

    const handleSetLoggedInUser = (user: User | undefined) => {
        setLoggedInUser(user);
    };

    const resetLocation = () => {
        setLocation(undefined);
        setLocationCode(undefined);
        window.location.assign('/');
    };

    useEffect(() => {
        setTimeout(() => {
            setLoggedInUser(undefined);
        }, 5000);
    }, [loggedInUser]);

    let showPrintPage = false;

    const printSearchParam = 'print';
    const printParamResult = new URLSearchParams(window.location.search).get(printSearchParam);
    let printLocationCode = '';

    if (printParamResult) {
        showPrintPage = true;
        printLocationCode = printParamResult;
    }

    return (
        <div className="app">
            {!showPrintPage && locationCode && !registerMode && (
                <Login
                    setRegisterMode={handleSetRegisterMode}
                    locationCode={locationCode}
                    setLoggedInUser={handleSetLoggedInUser}
                    tblocation={location}
                    resetLocation={resetLocation}
                    loggedInUser={loggedInUser}
                />
            )}

            {!showPrintPage && locationCode && registerMode && !loggedInUser && (
                <Register setRegisterMode={handleSetRegisterMode} kundeId={kundeId} />
            )}

            {!showPrintPage && !locationCode && (
                <LandingPage
                    setLocationCode={handleSetLocationCode}
                    setKundeId={handleSetKundeId}
                    setLocation={handleSetLocation}
                />
            )}

            {showPrintPage && <Print locationCode={printLocationCode}></Print>}
        </div>
    );
};

export default App;
