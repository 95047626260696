import { useEffect, useState } from 'react';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import Button from '@tidsbanken/components/buttons/Button';
import { AxiosError } from 'axios';
import QRCode from 'qrcode.react';
import stempleboks from '~src/Helpers/apiHelpers';
import { Anlegg } from '~src/Helpers/Types';
import logo from './Logo.png';
import './Print.less';

const locationCode = new URLSearchParams(window.location.search).get('print');

const getAnleggName = async (): Promise<string | undefined | void> => {
    return await stempleboks
        .post<Anlegg>('/anlegg', {
            id: locationCode,
        })
        .then((res) => res.data.Anlegg.Navn)
        .catch((e: AxiosError) => {
            console.error(e.message);
        });
};

type Props = {
    locationCode: string;
};

const Print = ({ locationCode }: Props): JSX.Element => {
    const [anleggNavn, setAnleggNavn] = useState<string>('');
    useEffect(() => {
        getAnleggName().then((res) => {
            if (res) {
                setAnleggNavn(res);
            }
        });
    }, []);

    return (
        <div className="print">
            <Button
                className="no-print"
                onClick={() => {
                    window.print();
                }}
                label="Print"
                kind="info"
                icon={faPrint}
                leftAlignIcon={true}
            ></Button>

            <h2>
                To sign in go to: <br />
                <span className="reg2build">reg2.build</span>
            </h2>

            <QRCode
                renderAs="svg"
                level="M"
                value={`https://min.tidsbanken.net/reg2build/bygg.asp?byggkode=${locationCode}`}
                imageSettings={{ src: logo, height: 30, width: 30, excavate: true }}
            />

            <h1>
                Location Code: <br />
                <span>{locationCode}</span>
            </h1>
            <h2>{anleggNavn}</h2>
        </div>
    );
};

export default Print;
