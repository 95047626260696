import './Loading.less';

type Props = {
    visible: boolean;
};

const Loading = ({ visible }: Props) => {
    if (visible) {
        return (
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default Loading;
