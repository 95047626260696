'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'party-horn';
var width = 512;
var height = 512;
var aliases = [];
var unicode = 'e31b';
var svgPathData = 'M503.5 42.3c10.1-8.6 11.4-23.7 2.8-33.8s-23.7-11.4-33.8-2.8l-10 8.5c-5.8 4.9-13.2 7.5-20.7 7.3c-42.5-1.1-78.3 31.7-80.8 74.2l-1.8 30c-1 16.6-15 29.4-31.6 29c-19.4-.5-38.2 6.1-53 18.6l-10 8.5c-10.1 8.6-11.4 23.7-2.8 33.8s23.7 11.4 33.8 2.8l10-8.5c5.8-4.9 13.2-7.5 20.7-7.3c42.5 1.1 78.3-31.7 80.8-74.2l1.8-30c1-16.6 15-29.4 31.6-29c19.4 .5 38.2-6.1 53-18.6l10-8.5zM232 0c-13.3 0-24 10.7-24 24c0 30.5-10.1 54.1-20.3 70.2c-5.1 8.1-10.2 14.2-13.8 18.2c-1.8 2-3.3 3.4-4.2 4.3c-.5 .4-.8 .7-1 .9l-.1 .1 0 0c-10.1 8.5-11.4 23.6-3 33.7c8.5 10.2 23.6 11.6 33.8 3.1L184 136c15.4 18.4 15.4 18.4 15.4 18.4l0 0 0 0 .1-.1 .2-.2 .7-.6c.5-.5 1.2-1.1 2.1-1.9c1.7-1.6 4-3.8 6.6-6.7c5.3-5.8 12.3-14.2 19.2-25.1C242.1 97.9 256 65.5 256 24c0-13.3-10.7-24-24-24zM96 32C96 14.3 81.7 0 64 0S32 14.3 32 32s14.3 32 32 32s32-14.3 32-32zM512 160c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM480 480c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zM395.5 342.1l.1-.1c.2-.2 .5-.6 .9-1.1c.9-1.1 2.3-2.8 4.3-4.8c4-4.2 10-9.7 17.8-15.1c15.6-10.7 37.9-20.2 66.8-17c13.2 1.5 25-8 26.5-21.2s-8-25-21.2-26.5c-43.1-4.8-76.8 9.7-99.2 25c-11.2 7.6-19.7 15.6-25.5 21.6c-2.9 3.1-5.2 5.7-6.7 7.6c-.8 1-1.4 1.8-1.9 2.4l-.6 .8-.2 .3-.1 .1 0 0 0 0c0 0 0 0 19.5 14l-19.5-13.9c-7.7 10.8-5.2 25.8 5.6 33.5c10.7 7.7 25.7 5.2 33.4-5.5l0 0zM121.2 192.7c-10.6 2.3-19.4 9.9-23.2 20L83.1 252.5 259.5 428.9 299.2 414c10.2-3.8 17.7-12.6 20-23.2s-1-21.7-8.6-29.4l-160-160c-7.7-7.7-18.8-11-29.4-8.6zM70.8 285.4L48.2 345.6 166.4 463.8l60.2-22.6L70.8 285.4zm62.7 190.7L35.9 378.5 2 468.8c-4.4 11.8-1.5 25 7.3 33.9s22.1 11.7 33.9 7.3l90.3-33.8z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faPartyHorn = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;