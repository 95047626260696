import React, { FormEvent, useEffect, useState } from 'react';
import Button from '@tidsbanken/components/buttons/Button';
import Input from '@tidsbanken/components/Input';
import Notification, { Kind } from '@tidsbanken/components/Notification';
import { AxiosError } from 'axios';
import meApi from '../../Helpers/apiHelpers';
import type { Anlegg, TBLocation } from '../../Helpers/Types';
import logoLink from '../../images/Tidsbanken.png';
import Loading from '../Loading/Loading';
import './LandingPage.less';

type Props = {
    setLocationCode: (locCode: string) => void;
    setKundeId: (kundeId: string) => void;
    setLocation: (location: TBLocation) => void;
};

const LandingPage = ({ setKundeId, setLocationCode, setLocation }: Props) => {
    const [inputLocationCode, setInputLocationCode] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(true);

    // notifications
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [notificationKind, setNotificationKind] = useState<Kind>();

    const formHandler = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        getAnlegg();
    };

    useEffect(() => {
        //check if url contains tidbanken
        if (window.location.href.includes('byggkode')) {
            //get string after "byggekode="
            const locationCodeParamResult = window.location.href.split('byggkode=')[1];
            console.log(locationCodeParamResult);

            //redirect to reg2.build
            window.location.href = 'https://reg2.build/#' + locationCodeParamResult;
        }

        const locationCodeParamResult = window.location.href.split('#')[1];
        const locationCode = locationCodeParamResult;

        if (locationCode) {
            getAnlegg(locationCode).catch(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    const getAnlegg = async (urlParameter = ''): Promise<void> => {
        await meApi
            .post<Anlegg>('/anlegg', {
                id: urlParameter === '' ? inputLocationCode : urlParameter,
            })
            .then((response) => {
                setNotificationMessage(response.data.Message ?? '');
                setNotificationKind(response.data.Kind);
                if (response && response.status === 200) {
                    // set global variables
                    setKundeId(response.data.Anlegg.KundeId);
                    setLocation(response.data.Anlegg);

                    // loading
                    setLoading(false);

                    // set global variable, need to be last because it umount component
                    setLocationCode(urlParameter === '' ? inputLocationCode : urlParameter);
                } else if (response.status >= 500) {
                    // notifications
                    setIsNotificationVisible(true);
                    setNotificationMessage('Something went wrong, contact support.');
                    setNotificationKind('error');

                    setLoading(false);
                } else {
                    console.log(response.data.Description);

                    setIsNotificationVisible(true);

                    setLoading(false);
                }
            })
            .catch((e: AxiosError) => {
                console.error(e.message);
                // notifications
                setIsNotificationVisible(true);
                setNotificationMessage('Something went wrong, contact support.');
                setNotificationKind('error');

                setLoading(false);
            });
    };

    //const logoLink = require('../../images/Tidsbanken.png');

    return (
        <div className="landing-page">
            <div className="image-container">
                <img src={logoLink} alt="logo" />
            </div>

            {isNotificationVisible && (
                <Notification
                    kind={notificationKind}
                    label={notificationMessage}
                    size="small"
                    className="notification-component"
                />
            )}

            {loading ? (
                <Loading visible={loading} />
            ) : (
                <form className="form-container" onSubmit={formHandler}>
                    <Input
                        className=""
                        autoFocus
                        placeholder="Location Code"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setInputLocationCode(e.target.value);
                        }}
                    />

                    <Button label="Enter" type="submit" kind="success" />
                </form>
            )}
        </div>
    );
};

export default LandingPage;
