import { FormEvent, useState } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import Button from '@tidsbanken/components/buttons/Button';
import Input from '@tidsbanken/components/Input';
import Notification from '@tidsbanken/components/Notification';
import meApi from '../../Helpers/apiHelpers';
import './Register.less';

type Props = {
    kundeId: string | undefined;
    setRegisterMode: (registerMode: boolean) => void;
};

const Register = ({ kundeId, setRegisterMode }: Props) => {
    const [ogNumber, setOgNumber] = useState<string>();
    const [company, setCompany] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [dateOfBirth, setDateOfBirth] = useState<string>();
    const [expirationDate, setExpirationDate] = useState<string>();
    const [cardNumber, setCardNumber] = useState<string>();
    const [mobile, setMobile] = useState<string>();
    const [saveCard, setSaveCard] = useState<boolean>(false);
    const [feedBackVisible, setFeedBackVisible] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>('default feedback');

    const onBack = () => {
        setRegisterMode(false);
    };

    const onRegister = async (e: FormEvent) => {
        e.preventDefault();
        setSaveCard(true);
        await meApi
            .post('/byggekort', {
                KundeId: kundeId,
                kortkode: cardNumber,
                FirstName: firstName,
                LastName: lastName,
                Organisasjonsnummer: ogNumber,
                Firmanavn: company,
                DateOfBirth: dateOfBirth,
                ExpirationDate: expirationDate,
                PhoneNumber: mobile ? mobile : null,
            })
            .then(() => {
                setSaveCard(false);
                setRegisterMode(false);
            })
            .catch((error) => {
                console.log(error);
                setFeedback('Card was not registrert');
                setFeedBackVisible(true);
                setSaveCard(false);
            });
    };

    return (
        <div className="register">
            <div className="header">
                <h2>We require the following information to register you.</h2>
                <h3>We save this information so you can quickly log in at other locations that use Tidsbanken.</h3>
            </div>
            {feedBackVisible && (
                <Notification kind="error" label={feedback} size="small" className="notification-component" />
            )}
            <form className="submit-form" onSubmit={onRegister}>
                <div className="form">
                    <div>
                        <label>
                            Organization number:
                            <Input
                                autoFocus
                                required
                                type="text"
                                placeholder="OG number"
                                onChange={(e) => setOgNumber(e.target.value)}
                            />
                        </label>
                        <label>
                            Company name:
                            <Input
                                required
                                type="text"
                                placeholder="Company name"
                                onChange={(e) => setCompany(e.target.value)}
                            />
                        </label>
                        <label>
                            First name:
                            <Input
                                required
                                type="text"
                                placeholder="First name"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </label>
                    </div>

                    <div>
                        <label>
                            Last name:
                            <Input
                                required
                                type="text"
                                placeholder="Last name"
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </label>
                        <label>
                            Date of birth:
                            <Input type="date" required onChange={(e) => setDateOfBirth(e.target.value)} />
                        </label>
                        <label>
                            Expiration date:
                            <Input type="date" required onChange={(e) => setExpirationDate(e.target.value)} />
                        </label>
                    </div>

                    <div>
                        <label>
                            Card number:
                            <Input
                                required
                                placeholder="Card number"
                                onChange={(e) => setCardNumber(e.target.value)}
                                type="number"
                            />
                        </label>
                        <label>
                            Mobile number: <p className="optional">- optional</p>
                            <Input
                                placeholder="Mobile number"
                                onChange={(e) => setMobile(e.target.value.length > 0 ? e.target.value : undefined)}
                                type="tel"
                                pattern="^\+?[0-9]{8,10}"
                            />
                        </label>
                    </div>
                </div>

                <div className="actions">
                    <Button
                        className="back"
                        leftAlignIcon
                        icon={faChevronLeft}
                        flat={true}
                        onClick={onBack}
                        label="Back"
                        kind="info"
                    />
                    <Button
                        className="register"
                        type="submit"
                        label="Save"
                        kind="info"
                        icon={saveCard ? faSpinner : undefined}
                        disabled={saveCard}
                        leftAlignIcon
                    />
                </div>
            </form>
        </div>
    );
};

export default Register;
