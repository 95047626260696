import axios from 'axios';

const baseUrl =
    window.location.href.includes('lokal.tidsbanken') || window.location.href.includes('sandkasse.tidsbanken')
        ? 'https://sandkasse.tidsbanken.net/api/stempleboks'
        : 'https://min.tidsbanken.net/api/stempleboks';

const instance = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
    validateStatus: (status: number) => {
        return status >= 200 && status <= 505;
    },
});

export default instance;
